.hideBodyScroll {
  overflow-y: hidden;
}

.popupBackground {
  z-index: 8;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  .popupContainer {
    background: #fff;
    border-radius: 30px 0;
    max-width: 704px;
    padding: 50px;
    width: 100%;
    max-height: 90vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .header {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 44px;
      color: #005f9c;
    }

    .infoText {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      align-self: flex-start;
      margin: 35px 0;
    }

    .main {
      width: 604px;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 16.75px;
        height: 52px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        background: #ffffff;
        border: 1px solid #e5e5e6;
        box-sizing: border-box;
        cursor: pointer;

        .rightBox {
          display: flex;
          align-items: center;
          gap: 10.75px;
        }
      }
      .row:nth-child(n + 2) {
        border-top: none;
      }

      .info {
        width: 604px;
        padding: 16px;
        background: #e5e9f3;

        &Row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 4px 0px;
          width: 572px;
          height: 19px;

          .right {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
          }

          .left {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;

            img {
              padding: 0 0 0 15px;
            }
          }
        }

        &ChainRow {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 4px 0px;
          width: 572px;
          height: 19px;

          .left {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;

            img {
              padding: 0 0 0 15px;
            }
          }
        }
      }
    }

    .closeBtnRow {
      display: flex;
      justify-content: center;
      margin: 35px 0 0;

      .closeBtn {
        align-items: center;
        background: #ff976e;
        border-radius: 20px 0;

        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        transition: filter 300ms;
        width: 180px;

        .text {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          line-height: 22px;
          z-index: 2;
        }

        &::before {
          border-radius: 20px 0;
          background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
          content: "";
          display: block;
          inset: 0;
          position: absolute;
          transform: scale3d(0, 1, 1);
          transform-origin: left center;
          transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
        }
      }

      .closeBtn:hover,
      .closeBtn:focus {
        color: #fff;

        &::before {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .popupBackground {
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 8;
  
    .popupContainer {
      overflow-y: auto;
      background: #fff;
      border-radius: 30px 0;
      max-width: 345px;
      padding: 24px;
      width: 100%;
      max-height: 90vh;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
  
      .header {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #005f9c;
      }
  
      .infoText {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        align-self: flex-start;
        margin: 16px 0;
      }
  
      .main {
        width: 297px;
  
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 16.75px;
          height: 52px;
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          background: #ffffff;
          border: 1px solid #e5e5e6;
          box-sizing: border-box;
          cursor: pointer;
  
          .rightBox {
            display: flex;
            align-items: center;
            gap: 10.75px;
          }
        }
        .row:nth-child(n + 2) {
          border-top: none;
        }
  
        .info {
          width: 297px;
          overflow-y: auto;
          padding: 16px;
          background: #e5e9f3;
  
          &Row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin: 4px 0px;
            width: 265px;
            // height: 19px;
            height: 40px;
  
            .right {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
            }
  
            .left {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 19px;
              display: flex;
              align-items: center;
  
              img {
                padding: 0 0 0 15px;
              }
            }
          }
  
          &ChainRow {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 265px;
            height: 40px;
  
            .left {
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              display: flex;
              align-items: center;
  
              img {
                padding: 0 0 0 15px;
              }
            }
          }
        }
      }
  
      .closeBtnRow {
        display: flex;
        justify-content: center;
        margin: 35px 0 0;
  
        .closeBtn {
          align-items: center;
          background: #ff976e;
          border-radius: 20px 0;
  
          cursor: pointer;
          display: flex;
          height: 40px;
          justify-content: center;
          overflow: hidden;
          position: relative;
          transition: filter 300ms;
          width: 180px;
  
          .text {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 22px;
            z-index: 2;
          }
  
          &::before {
            border-radius: 20px 0;
            background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
            content: "";
            display: block;
            inset: 0;
            position: absolute;
            transform: scale3d(0, 1, 1);
            transform-origin: left center;
            transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
          }
        }
  
        .closeBtn:hover,
        .closeBtn:focus {
          color: #fff;
  
          &::before {
            transform: scale3d(1, 1, 1);
          }
        }
      }
    }
  }
}