.errorContainer {
  z-index: 5;
  position: relative;
  top: 170px;
  right: 26.79px;
  padding: 0 32px 32px 32px;
  width: 367px;
  max-height: 370px;
  background: #8e70e3;
  border-radius: 24px 0px;

  .header {
    margin: 0 0 25px 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #ffffff;
  }

  .main {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    a {
      text-decoration: underline;
      color: #c6bbec;
    }
  }

  .blockArrow {
    position: relative;
    top: 30px;
    left: 317px;
  }
}

.errorSignContainer {
  z-index: 5;
  position: relative;
  top: 270px;
  right: 40px;
  padding: 0 32px 32px 32px;
  width: 367px;
  max-height: 380px;
  background: #8e70e3;
  border-radius: 24px 0px;

  .header {
    margin: 0 0 25px 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #ffffff;
  }

  .main {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    a {
      color: #c6bbec;
    }
  }

  .blockArrow {
    position: relative;
    top: 270px;
    left: 320px;
  }
}

@media (max-width: 1300px) {
  .errorContainer {
    top: 170px;

    // .blockArrow {
    //   top: 15px;
    //   left: 326px;
    // }
  }

  .errorSignContainer {
    top: 170px;

    .blockArrow {
      top: 300px;
      left: 326px;
    }
  }
}

@media (max-width: 800px) {
  .errorContainer {
    top: 30px;
    right: -328px;

    .blockArrow {
      display: none;
    }
  }

  .errorSignContainer {
    z-index: 5;
    position: relative;
    top: 10px;
    left: 610px;
    padding: 0 32px 32px 32px;
    width: 367px;
    max-height: 380px;
    background: #8e70e3;
    border-radius: 24px 0px;
  
    .header {
      margin: 0 0 25px 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: #ffffff;
    }
  
    .main {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
  
      a {
        color: #c6bbec;
      }
    }
  
    .blockArrow {
      display: none;
    }
  }
}