.defaultContainer {
  width: 100%;
  height: 42.25px;
  background: #e5e9f3;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 29.22px 0 14.18px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #a9b7d7;

  .loader {
    border: 3px solid #a9b7d7;
    border-radius: 50%;
    border-top: 3px solid #e5e9f3;
    width: 18px;
    height: 18px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.noSignContainer {
  width: 100%;
  height: 42.25px;
  background: #e5e5e6;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 29.22px 0 14.18px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #98999c;
}

.disabledContainer {
  width: 100%;
  height: 42.25px;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 29.22px 0 14.18px;

  border: 1px solid #e5e9f3;
  border-top: none;
}

.signContainer {
  width: 100%;
  height: 42.25px;
  background: #e6f0ed;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14.18px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6fac9d;
  cursor: pointer;

  svg {
    path {
      fill: #6fac9d;
    }
  }
}

.errContainer {
  width: 100%;
  height: 42.25px;
  background: #fce0e0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14.18px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #eb3232;
  cursor: pointer;

  svg {
    path {
      fill: #eb3232;
    }
  }
}

.warinngContainer {
  width: 100%;
  height: 42.25px;
  background: #feeacd;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14.18px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9e6e14;
  cursor: pointer;

  svg {
    path {
      fill: #9e6e14;
    }
  }
}

@media(max-width: 850px) {
  .warinngContainer, .defaultContainer, .noSignContainer, .signContainer,.errContainer, .disabledContainer {
    position:relative;
    z-index: 1;
    left: 298px;
    top: 453px;
    width: 284px;
  }
}

@media (min-width: 376px) and (max-height: 800px) {
  .warinngContainer, .defaultContainer, .noSignContainer, .signContainer,.errContainer, .disabledContainer {
    display: none;
  }
}
