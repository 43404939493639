@import "../../styles/styles.scss";

.pageContainer {
  display: flex;
  justify-content: flex-end;
  margin: 156px 0 0;
  background: #e5e5e5;

  .contentWrapper {
    display: flex;
    width: 100%;

    .pdfContainer {
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;

      .pdfNavigationContainer {
        display: flex;
        width: 100%;
        max-width: 880px;
        height: 270.48px;
        align-items: center;
        justify-content: space-between;
        margin: 26.86px 0;

        .navDocument {
          display: flex;
          height: 270px;
          flex-flow: row nowrap;
          gap: 8.75px;
          overflow-y: hidden;
        }
      }

      .pdfPreview {
        width: 100%;
        max-width: 880px;
        background: #fff;
        // overflow-y: scroll;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
      }
    }

    .actionContainer {
      width: 400px;
      padding: 25px;
      background: #fff;

      .backContainer {
        position: fixed;
        display: flex;
        align-items: center;
        gap: 8px;

        &Text {
          color: #005f9c;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
      }

      .documentName {
        overflow: auto;
        max-height: 120px;
        max-width: 300px;
        overflow: auto;
        position: fixed;
        margin: 36px 0 25px;
        color: #121212;
        font-family: Nunito Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      .question {
        position: fixed;
        display: block;
        margin: 170px 0 25px 0;
        color: #000;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: 24px;
      }

      .allowCheckBoxContainer {
        position: fixed;
        display: flex;
        align-items: center;
        margin: 200px 0 0 0;
        cursor: pointer;

        .checkboxImg18 {
          width: 18px;
          margin: 0 5px 0 0;
        }

        .checkboxText {
          margin: 0 5px 0 0;
          color: $_gray;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
        }

        .linkToPdf {
          color: $_gray;
          text-decoration: underline;
        }
      }

      .btnContainer {
        position: fixed;
        display: flex;
        width: 100%;
        // flex-flow: column;
        align-items: center;
        margin: 250px 0 0 0;
        // justify-content: center;

        .btn {
          position: relative;
          overflow: hidden;
          width: 100%;
          max-width: 290px;
          height: 50px;
          border: none;
          background: $salmon;
          border-radius: 16px 0;

          cursor: pointer;
          transition: filter 300ms;

          .text {
            position: relative;
            z-index: 2;
            color: #fff;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          &::before {
            position: absolute;
            display: block;
            background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
            border-radius: 16px 0;
            content: "";
            inset: 0;
            transform: scale3d(0, 1, 1);
            transform-origin: left center;
            transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
          }
        }

        .btn:hover,
        .btn:focus {
          color: #fff;

          &::before {
            transform: scale3d(1, 1, 1);
          }
        }

        .btnDisable {
          position: relative;
          overflow: hidden;
          width: 100%;
          max-width: 290px;
          height: 50px;
          padding: 10px;
          border: none;
          background: #c4c4c4;
          background-color: #bababb;
          border-radius: 16px 0;
          color: white;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}

@media (max-width: 850px) {

  .pageContainer {
    margin-top: 84px;

    .contentWrapper {
      justify-content: center;
      .pdfContainer {
        .pdfNavigationContainer {
          display: none;
        }

        .mobileNavigationContainer {
          position: fixed;
          z-index: 4;
          display: flex;
          width: 334px;
          height: 54px;
          align-items: center;
          justify-content: space-between;

          .backContainer {
            display: flex;
            align-items: center;
            gap: 8px;

            &Text {
              color: #005f9c;
              font-family: Nunito Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
            }
          }

          .infoIcon {
            width: 24px;
          }
        }

        .mobileInfoContainer {
          width: 100%;
          min-width: 375px;
          min-height: 432px;
          padding: 13px 20px;
          margin-top: 54px;
          background: #fff;

          .documentName {
            margin: 0 0 25px;
            color: #121212;
            font-family: Nunito Sans;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
          }

          .documentText {
            margin: 25px 0;
            color: #000;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
          }

          .question {
            color: #000;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: bold;
            line-height: 24px;
          }
        }

        .pdfPreview {
          max-width: 334px;
          margin-bottom: 150px;
          background: #e5e5e5;

          .navDocument {
            margin-top: 54px;

            .mobilePage {
              margin-bottom: 10px;
            }

            .mobilePage:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }

      .actionContainer {
        display: none;
      }

      /*.mobileFooter {
        position: fixed;
        bottom: 0;
        max-width: 375px;
        width: 100%;
        height: 142px;
        padding: 24px 20px;
        background: #fff;

        .allowCheckBoxContainer {
          display: flex;
          align-items: center;
          cursor: pointer;

          .checkboxImg18 {
            width: 18px;
            margin: 0 5px 0 0;
          }

          .checkboxText {
            margin: 0 5px 0 0;
            color: $_gray;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
          }

          .linkToPdf {
            color: $_gray;
            text-decoration: underline;
          }
        }

        .btnContainer {
          display: flex;
          width: 100%;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          margin: 16px 0 0;

          .btn {
            position: relative;
            overflow: hidden;
            width: 100%;
            max-width: 338px;
            height: 50px;
            border: none;
            background: $salmon;
            border-radius: 16px 0;

            cursor: pointer;
            transition: filter 300ms;

            .text {
              position: relative;
              z-index: 2;
              color: #fff;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            &::before {
              position: absolute;
              display: block;
              background: linear-gradient(
                198.14deg,
                #a9b7d7 2.65%,
                #005f9c 100%
              );
              border-radius: 16px 0;
              content: "";
              inset: 0;
              transform: scale3d(0, 1, 1);
              transform-origin: left center;
              transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
            }
          }

          .btn:hover,
          .btn:focus {
            color: #fff;

            &::before {
              transform: scale3d(1, 1, 1);
            }
          }

          .btnDisable {
            width: 100%;
            max-width: 338px;
            height: 50px;
            padding: 10px;
            border: none;
            margin: 0 54.5px;
            background: #c4c4c4;
            background-color: #bababb;
            border-radius: 16px 0;
            color: white;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }*/
    }
  }

  .mobileFooter {
    position: fixed;
    z-index: 5;
    bottom: 0;
    width: 375px;
    height: 142px;
    padding: 24px 20px;
    margin: 0 auto;
    background: #fff;

    .allowCheckBoxContainer {
      display: flex;
      align-items: center;
      cursor: pointer;

      .checkboxImg18 {
        width: 18px;
        margin: 0 5px 0 0;
      }

      .checkboxText {
        margin: 0 5px 0 0;
        color: $_gray;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
      }

      .linkToPdf {
        color: $_gray;
        text-decoration: underline;
      }
    }

    .btnContainer {
      display: flex;
      width: 100%;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin: 16px 0 0;

      .btn {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 338px;
        height: 50px;
        border: none;
        background: $salmon;
        border-radius: 16px 0;

        cursor: pointer;
        transition: filter 300ms;

        .text {
          position: relative;
          z-index: 2;
          color: #fff;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        &::before {
          position: absolute;
          display: block;
          background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
          border-radius: 16px 0;
          content: "";
          inset: 0;
          transform: scale3d(0, 1, 1);
          transform-origin: left center;
          transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
        }
      }

      .btn:hover,
      .btn:focus {
        color: #fff;

        &::before {
          transform: scale3d(1, 1, 1);
        }
      }

      .btnDisable {
        width: 100%;
        max-width: 338px;
        height: 50px;
        padding: 10px;
        border: none;
        margin: 0 54.5px;
        background: #c4c4c4;
        background-color: #bababb;
        border-radius: 16px 0;
        color: white;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
