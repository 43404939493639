.hideBodyScroll {
  overflow-y: hidden;
}

.pageContainer {
  display: flex;
  justify-content: center;
  margin: 106px 0 0;

  .contentWrapper {
    width: 100%;
    max-width: 2000px;
    height: 100vh;

    .header {
      display: flex;
      margin: 50px 0 0;
      margin-left: 180px;

      &Text {
        z-index: 1;
        color: #000;
        font-family: Nunito Sans;
        font-size: 64px;
        font-style: normal;
      }
    }

    .mainContentContainer {
      z-index: 2;
      display: flex;
      justify-content: center;

      .mainContent {
        display: flex;
        width: 100%;
        max-width: 1760px;
        height: 72vh;
        min-height: 193px;
        flex-flow: column;
        align-items: flex-end;
        justify-content: center;
        margin: -34px 80px 0;
        background-image: url("./assets/background.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 56px 0;

        .pdfContent {
          display: flex;
          align-items: flex-start;

          .pdfBlockPosition {
            position: relative;
            top: 41px;
            left: -13px;
          }
        }
      }
    }

    .footer {
      margin: 10px 0 10px 80px;

      &Text {
        color: #a9b7d7;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
      }
    }
  }
}

// mobile version
@media (max-width: 375px) {
  .pageContainer {
    .contentWrapper {
      .mainContentContainer {
        .mainContent {
          height: 338px;
        }
      }

      .footer {
        margin-top: 170px !important;
      }
    }
  }
}

@media (max-width: 1399px) {
  .pageContainer {
    .contentWrapper {
      .header {
        margin: 30px 15.5px 0 18.5px;

        &Text {
          margin-bottom: 5px;
          margin-left: 160px;
          color: #000;
          font-size: 48px;
          font-style: normal;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .pageContainer {
    .contentWrapper {
      .mainContentContainer {
        .mainContent {
          .pdfContent {
            .pdfBlockPosition {
              position: relative;
              top: 68px;
              left: -13px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1440px) and (max-height: 1000px) {
  .pageContainer {
    .contentWrapper {
      .mainContentContainer {
        .mainContent {
          height: 65vh;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .pageContainer {
    .contentWrapper {
      .header {
        margin: 0 15.5px 0 18.5px;

        &Text {
          min-width: 341px;
          margin-bottom: 5px;
          margin-left: 10px;
          color: #000;
          font-size: 32px;
          font-style: normal;
          line-height: 40px;
        }
      }

      .mainContentContainer {
        .mainContent {
          margin: -15px 16.5px 0;
          border-radius: 16px 0;
        }
      }

      .footer {
        display: flex;
        justify-content: center;
        margin: 20px 0 15px;
      }
    }
  }
}
