// @import "../../../../ui/src/themes/vidua/_colors.module";
$red: #eb3232;
$softRed: #f59898;
$salmon: #ff976e;
$softSalmon: #ffc1b5;
$darkBlue: #08476f;
$blue: #005f9c;
$softBlue: #a9b7d7;
$lightBlue: #e5e9f3;
$darkYellow: #9e6d0e;
$yellow: #e7b000;
$softYellow: #f5d690;
$lightYellow: #feeacd;
$green: #6fac9d;
$softGreen: #adcdc4;
$purple: #8e70e3;
$softPurple: #c8bbec;
$lightPurple: #f4f1fc;
$grey: #98999c;
$softGrey: #e5e5e5;
$black: #000;
$white: #fff;
$whiteTransparent: rgba(255, 255, 255, 0.65);
$softBlack: #222;
$lightGrey: #eee;
$darkGrey: #565656;

// local colors
$_gray: #949494;
