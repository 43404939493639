.linkImg {
  height: 17px;
}

.line {
  position: relative;
  z-index: 2;
  top: 250px;
  width: 100%;
  height: 99px;
  background: #a9b7d7;
}

.line2 {
  position: relative;
  z-index: 2;
  top: 250px;
  width: 100%;
  height: 99px;
  background: #6fac9d;
}

.container {
  position: relative;

  display: flex;
  width: 305.21px;
  height: 398.75px;
  max-height: 466px;
  box-sizing: border-box;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e9f3;
  border-bottom: none;
  background: #fff;
  border-radius: 56px 0 0;

  .page {
    display: flex;
    overflow: hidden;
    width: 195px;
    height: 270.48px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: 1px solid #98999c;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
    gap: 20px;
  }

  .link {
    margin: 10px 0 0;
    color: #005f9c;
    cursor: pointer;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
  }
}

.containerSign {
  position: relative;

  display: flex;
  width: 305.21px;
  height: 440px;
  max-height: 466px;
  box-sizing: border-box;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e9f3;
  border-bottom: none;
  background: #fff;
  border-radius: 56px 0 0;

  .page {
    display: flex;
    overflow: hidden;
    width: 195px;
    height: 270.48px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: 1px solid #98999c;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
    gap: 20px;
  }

  .link {
    margin: 10px 0 0;
    color: #005f9c;
    cursor: pointer;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
  }
}

@media (max-width: 1300px) {
  .line {
    position: relative;
    z-index: 2;
    top: 163px;
    width: 100%;
    height: 72px;
    background: #a9b7d7;
  }

  .line2 {
    position: relative;
    z-index: 2;
    top: 163px;
    width: 100%;
    height: 72px;
    background: #6fac9d;
  }

  .container {
    position: relative;

    display: flex;
    width: 247px;
    height: 325px;
    max-height: 466px;
    box-sizing: border-box;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e9f3;
    background: #fff;
    border-radius: 28px 0 0;

    .page {
      display: flex;
      overflow: hidden;
      width: 158px;
      height: 219px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border: 1px solid #98999c;
      background: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0;
      gap: 20px;
    }

    .link {
      margin: 10px 0 0;
      color: #005f9c;
      cursor: pointer;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
      text-decoration-line: underline;
    }
  }
}

@media (max-width: 850px) {
  .container, .line {
    display: none;
  }
}

@media (max-height: 800px) {
  .container, .line {
    display: none;
  }
}
