.popupBackground {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);

  .popupContainer {
    width: 100%;
    max-width: 448px;
    min-width: 336px;
    max-height: 456px;
    padding: 24px;
    background: #fff;
    border-radius: 32px 0;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .header {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #000000;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    cursor: pointer;
  }

  .prolong {
    background: #FF976E;
    border-radius: 16px 0 0 0;
    border: none;
    color: #FFFFFF;
  }

  .logout {
    color: #FF976E;
    border-radius: 0 0 16px 0;
    border: solid 2px #FF976E;
    background: #FFFFFF;
  }

  .timer {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #757C8C;
  }
}