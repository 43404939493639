.row {
  height: 30px;
  display: flex;
  justify-content: flex-end;

  .userProfileContainer {
    display: flex;
    align-items: flex-end;
    margin: 0 40px 0 0;

    .label {
      margin: 0 8px 0 0;
      display: flex;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      color: #98999c;
    }

    .nameRow {
      margin: 0 33px 0 0;
      display: flex;
      align-items: center;

      img {
        margin: 0 5px 0 0;
        width: 14px;
      }

      .nameContainer {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
      }
    }

    .logOutRow {
      display: flex;
      align-items: center;

      img {
        width: 14px;
        margin: 0 5px 0 0;
      }

      div {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: #005f9c;
      }
    }

    .language {
      cursor: pointer;
      margin: 0 0 2px 32px;
      display: flex;
      align-items: flex-end;

      .flag {
        height: 11px;
      }

      .arrow {
        margin: 0 0 0 4px;
        align-self: center;
        width: 7.5px;
        height: 3.75px;
      }
    }
  }
}

// small
@media (max-width: 850px) {
  .row {
    flex-flow: row wrap;

    .userProfileContainer {
      flex-flow: row wrap;
      justify-content: flex-end;
      gap: 6.5px;
      width: 276px;
      position: relative;
      margin: 7px 20px 0 0;

      .nameRow {
        margin: 0;
        .nameContainer {
          max-width: 185px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
