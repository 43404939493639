.content {
  // border: 1px solid red;
  text-align: center;
  padding: 15px 25px 0 25px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #757c8c;
}
