.background {
  align-items: center;
  background: rgba(34, 34, 34, 0.25);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;

  .errorContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    max-width: 448px;
    width: 95%;
    // height: 560.59px;
    background: #ffffff;
    border-radius: 30px 0px;

    .headerContainer {
      width: 100%;
      height: 56px;

      .text {
        position: relative;
        top: -14px;
        left: 0px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        color: #000000;
      }

      .errCode {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        color: #98999c;
      }
    }

    .main {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: 24px 0px;
    }

    .photo {
      max-width: 403.5px;
      width: 100%;
      border-radius: 30px 0px;
    }

    .closeBtnRow {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 25px 0 0 0;

      .closeBtn {
        align-items: center;
        background: #ff976e;
        border-radius: 20px 0;

        cursor: pointer;
        display: flex;
        height: 56px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        transition: filter 300ms;
        min-width: 308px;
        max-width: 400px;
        width: 100%;

        .text {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          line-height: 22px;
          position: relative;
          z-index: 2;
        }

        &::before {
          border-radius: 20px 0;
          background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
          content: "";
          display: block;
          inset: 0;
          position: absolute;
          transform: scale3d(0, 1, 1);
          transform-origin: left center;
          transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
        }
      }

      .closeBtn:hover,
      .closeBtn:focus {
        color: #fff;

        &::before {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}
