// @import "../../../../ui/src/themes/vidua/_colors.module";
@import "../../styles//base/colors";

.dragndrop {
  // width: 100%;
  width: 447px;
  height: 440px;
  max-height: 495px;
  border: 1px solid #e5e9f3;
  border: 1px solid $lightBlue;
  margin: 140px 37px 43px 0;
  margin-right: 75px;
  background: $white;
  border-radius: 56px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  height: 150px;
  align-items: center;
  padding: 50px 30px 40px 41px;
  color: $softBlack;
  cursor: pointer;
  
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 33px;
  
  &:focus {
    outline: none;
  }
  
  &Img {
    margin: 0 50px 0 0;
  }
  
  &ImgRoutate {
    margin: 0 50px 0 0;
    animation: rotation 2s infinite linear;
  }

  &.signed {
    cursor: default;
  }
}

.selectContainer {
  display: flex;
  height: 100%;
  height: 201px;
  max-height: 99px;
  align-items: center;
  justify-content: center;
  background: $lightBlue;

  .saveImg {
    width: 30px;
  }

  .selectImg {
    width: 155px;
  }
}

.drag {
  color: $_gray;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.fileName {
  overflow: auto;
  max-height: 88px;
  display: flex;
  min-height: 40px;
  align-items: center;
  // padding: 0 0 0 60px;
  color: $darkBlue;

  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.fileNameGreen {
  display: flex;
  max-width: 300px;
  min-height: 40px;
  align-items: center;
  padding: 25px;
  color: #08476f;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;

  &Text {
    color: #fff;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Tooltip {
    position: absolute;
    top: -10px;
    width: 100px;
    background: #fff;
  }
}

.howItWorkContainer {
  margin: 10px 0 25px;
  text-align: center;

  a {
    color: #005f9c;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-decoration: underline;
  }
}

.allowCheckBoxContainer {
  // border: 1px solid blue;
  display: flex;
  align-items: center;
  margin: 50px 54.5px 25px;
  cursor: pointer;

  .checkboxImg18 {
    width: 18px;
    margin: 0 5px 0 0;
    margin-right: 15px;
  }

  .checkboxText {
    margin: 0 5px 0 0;
    color: $_gray;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }

  .linkToPdf {
    color: $_gray;
    text-decoration: underline;
  }
}

.btnContainer {
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 52px;

  .btn {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 338px;
    height: 50px;
    border: none;
    background: $salmon;
    border-radius: 16px 0;

    cursor: pointer;
    transition: filter 300ms;

    .text {
      position: relative;
      z-index: 2;
      color: #fff;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &::before {
      position: absolute;
      display: block;
      background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
      border-radius: 16px 0;
      content: "";
      inset: 0;
      transform: scale3d(0, 1, 1);
      transform-origin: left center;
      transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
    }
  }

  .btn2 {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 338px;
    height: 50px;
    border: none;
    background: $salmon;
    border-radius: 16px 0;

    cursor: pointer;
    transition: filter 300ms;

    .text {
      position: relative;
      z-index: 2;
      color: #fff;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &::before {
      position: absolute;
      display: block;
      background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
      border-radius: 16px 0 0;
      content: "";
      inset: 0;
      transform: scale3d(0, 1, 1);
      transform-origin: left center;
      transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
    }
  }

  .btn:hover,
  .btn:focus,
  .btn2:hover,
  .btn2:focus {
    color: #fff;

    &::before {
      transform: scale3d(1, 1, 1);
    }
  }

  .btnDisable {
    width: 100%;
    max-width: 338px;
    height: 50px;
    padding: 10px;
    border: none;
    margin: 0 54.5px;
    background: #c4c4c4;
    background-color: #bababb;
    border-radius: 16px 0;
    color: white;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
  }
}

.cancelWrapper {
  width: 100%;
  max-width: 338px;
}

 .cancelButton {
  width: 100%;
  max-width: 338px;
  height: 50px;
  border: 1px solid $salmon;
  margin: 10px 0 50px;
  background: #fff;
  border-radius: 15px 0;
  color: $salmon;
  cursor: pointer;
  font-family: Nunito Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}

.filenameContainer {
  height: 100%;
  overflow: auto;
  max-height: 70px;

  &Name {
    max-width: 380px;
    overflow-y: auto;
  }
}
.tooltip {
  overflow-y: auto;
  padding: 5px;
  max-height: 80px;
  max-width: 450px;
  height: 100%;
  z-index: 3;
  position: absolute;
  // top: -10px;
  // right: -10px;
  background: #fff;
}

.topLeftRound {
  border-radius: 16px 0 0 !important;
}

.bottomRightRound {
  border-radius: 0 0 16px !important;
}

@media (max-width: 1300px) {
  // body {
  //   overflow-y: scroll !important;
  // }

  .dragndrop {
    width: 286px;
    height: 325px;
    min-height: initial;
    align-self: center;
    border: 1px solid #e5e9f3;
    background: #fff;
    border-radius: 25px 0;
    cursor: pointer;
  }

  .header {
    height: 84px;
    font-size: 18px;
    line-height: 25px;

    &:focus {
      outline: none;
    }

    &Img {
      margin: 0 25px 0 0;
    }

    &ImgRoutate {
      margin: 0 50px 0 0;
      animation: rotation 2s infinite linear;
    }
  }

  .selectContainer {
    max-height: 72px;
  }

  .allowCheckBoxContainer {
    // margin: 64px 24.5px 25px;
    margin: 20px 24.5px 25px;
  }

  .btnContainer {
    margin-bottom: 25px;

    .btn {
      width: 240px;
    }

    .btn2 {
      width: 240px;
    }

    .btnDisable {
      width: 221px;
      margin: 0 37px;
    }
  }

  .cancelWrapper {
    width: 240px;
  }

  .cancelButton {
    width: 240px;
  }

  .filenameContainer {
    &Name {
      max-width: 250px;
      overflow-y: auto;
    }

    // .noWrap {
    //   width: 200px;
    // }
  }

  .howItWorkContainer {
    margin: 10px 0 0px;
  }
}

@media (max-width: 999px) {
  .dragndrop {
    width: 286px;
    min-height: initial;
    margin-right: 30px;
    background: #fff;
    border-radius: 25px 0;
    cursor: pointer;
  }
}

@media (max-height: 800px) {
  .dragndrop {
    width: 286px;
    // height: 369px;
    height: 325px;
    min-height: initial;
    // margin-right: 75px;
    background: #fff;
    border-radius: 25px 0;
    cursor: pointer;
  }

  .header {
    height: 84px;
    font-size: 18px;
    line-height: 25px;

    &:focus {
      outline: none;
    }

    &Img {
      margin: 0 25px 0 0;
    }

    &ImgRoutate {
      margin: 0 50px 0 0;
      animation: rotation 2s infinite linear;
    }
  }

  .selectContainer {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 78px;
    align-items: center;
    justify-content: center;
  }

  .allowCheckBoxContainer {
    // margin: 64px 30px 25px;
    margin: 20px 30px 25px;
  }

  .btnContainer {
    margin-bottom: 25px;

    .btn {
      width: 240px;
    }

    .btn2 {
      width: 240px;
    }

    .btnDisable {
      width: 221px;
      margin: 0 37px;
    }
  }

  .cancelWrapper {
    width: 240px;
  }

  .cancelButton {
    width: 240px;
  }

  .filenameContainer {
    &Name {
      max-width: 250px;
      overflow-y: auto;
    }

    // .noWrap {
    //   width: 200px;
    // }
  }

  .howItWorkContainer {
    margin: 10px 0px 0px 0px;
  }
}

@media (max-width: 414px) {
  body {
    overflow-y: scroll !important;
  }

  .dragndrop {
    height: 369px;
    margin-top: 21em;
    margin-right: 3em;
  }

  .allowCheckBoxContainer {
    margin: 70px 30px 25px;
  }

  .fileName {
    // padding: 0 0 0 60px;

    .filenameContainer {
      display: flex;
      flex-flow: column;
      align-items: center;

      &Name {
        width: 240px;
        overflow-y: auto;
      }

      .prompt {
        margin-top: 5px;
        color: #e5e9f3;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
      }
    }
  }

  .howItWorkContainer {
    margin-bottom: 0px;
  }
}

@media (max-width: 850px){
  body {
    overflow-y: scroll !important;
  }

  .dragndrop {
    height: 369px;
    margin-top: 21em;
    margin-right: 3em;
  }

  .allowCheckBoxContainer {
    margin: 70px 30px 25px;
  }

  .fileName {
    // padding: 0 0 0 60px;

    .filenameContainer {
      display: flex;
      flex-flow: column;
      align-items: center;

      &Name {
        width: 240px;
        overflow-y: auto;
      }

      .prompt {
        margin-top: 5px;
        color: #e5e9f3;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
      }
    }
  }

  .howItWorkContainer {
    margin-bottom: 0px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}