.hideBodyScroll {
  overflow-y: hidden;
}

.popupBackground {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  
  .popupContainer {
    width: 100%;
    max-width: 450px;
    // max-height: 90vh;
    padding: 50px;
    background: #fff;
    border-radius: 30px 0;

    .header {
      color: #005f9c;
      font-family: Nunito Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: normal;
      line-height: 44px;
      text-align: center;
    }

    .textContainer {
      width: 100%;
      max-width: 350px;
      // height: 617.16px;
      // height: 60vh;
      margin: 35px 0 0 0;
      color: #000;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      overflow-y: auto;

      .row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
