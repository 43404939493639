.hideBodyScroll {
  overflow-y: hidden;
}

.popupBackground {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);

  .popupContainer {
    width: 100%;
    max-width: 650px;
    padding: 50px;
    background: #fff;
    border-radius: 30px 0;

    .header {
      color: #005f9c;
      font-family: Nunito Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: normal;
      line-height: 44px;
      text-align: center;
    }

    .textContainer {
      width: 100%;
      max-width: 550px;
      max-height: 617.16px;
      margin: 35px 0 0;
      color: #000;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      overflow-y: auto;
    }

    .closeBtnRow {
      display: flex;
      justify-content: center;
      margin: 35px 0 0;

      .closeBtn {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 180px;
        height: 40px;
        align-items: center;
        justify-content: center;
        background: #ff976e;
        border-radius: 20px 0;

        cursor: pointer;
        transition: filter 300ms;

        .text {
          position: relative;
          z-index: 2;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          line-height: 22px;
        }

        &::before {
          position: absolute;
          display: block;
          background: linear-gradient(198.14deg, #a9b7d7 2.65%, #005f9c 100%);
          border-radius: 20px 0;
          content: '';
          inset: 0;
          transform: scale3d(0, 1, 1);
          transform-origin: left center;
          transition: transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s;
        }
      }

      .closeBtn:hover,
      .closeBtn:focus {
        color: #fff;

        &::before {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}
