* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
}

a:link {
  text-decoration: none;
}

p {
  line-height: 1.6;
}